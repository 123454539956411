<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <div>
          <group-documents-header
            show-reload-resource
            :loading-reload-resource="loading.reloadResource"
            back-button-text="Indietro"
            :back-button-route="calculateBackRoute"
            @clickCreateDocument="isShow.create = true"
            @clickCreateFolder="isShow.folder_create = true"
            @clickReloadResource="getData"
          />

          <group-card
            section-name="Spazio condiviso"
          />

          <v-divider></v-divider>

          <!-- BREADCRUMB -->
          <v-row class="mt-0">
            <v-col cols="12">
              <v-breadcrumbs
                class="pl-0"
                large
                :items="calculateBreadcrumb"
                divider=">"
              >
              </v-breadcrumbs>
            </v-col>
          </v-row>

          <!-- GROUP FOLDERS -->
          <v-row class="mt-0">
            <v-col
              v-for="(folder,idx) in currentLevelFolders"
              :key="'F'+idx"
              cols="12"
              sm="12"
              md="3"
            >
              <folder-card
                folder-route="groupFolderView"
                :folder-obj="folder"
                :folder-permissions="folder.group_folder_permissions"
                :go-to-folder-target-id="folder.group_id"
                :api-routes="apiRoutes"
                :api-target-id="folder.group_id"
                @reloadData="getData"
                @droppedItem="setIsDropped"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="(doc,idx) in currentLevelDocuments"
              :key="idx"
              cols="12"
              sm="12"
              md="4"
            >
              <group-documents-card
                is-draggable
                :document-obj="doc"
                document-route="groupDocumentFiles"
                :is-dropped="isDropped[doc.id]"
                @reload-data="getData"
              />
            </v-col>
          </v-row>

          <create-group-document
            v-if="isOwner && isPageFullyLoaded"
            no-auto-import
            no-priority
            :is-show="isShow.create"
            :group-id="loggedUserGroup.id"
            :folder-id="currentFolder.id"
            @close="isShow.create=false"
            @reload-data="getData"
          ></create-group-document>
          <create-group-folder
            v-if="isOwner && isPageFullyLoaded"
            is-plugin-version
            :is-show="isShow.folder_create"
            :group-id="loggedUserGroup.id"
            :folder-ref="currentFolder.unique_ref"
            @close="isShow.folder_create=false"
            @reload-data="getData"
          ></create-group-folder>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import GroupDocumentsHeader from '@/components/section-headers/group-documents-header.vue'
import GroupCard from '@/components/cards/group-card.vue'
import GroupDocumentsCard from '@/components/cards/group-documents-card.vue'
import CreateGroupDocument from '@/components/modals/create-group-document.vue'
import CreateGroupFolder from '@/components/modals/create-group-folder.vue'
import FolderCard from '@/components/cards/folder-card.vue'

export default {
  name: 'GroupFolder',
  components: {
    FolderCard,
    CreateGroupDocument,
    GroupDocumentsCard,
    GroupCard,
    GroupDocumentsHeader,
    CreateGroupFolder,
  },
  data() {
    return {
      isDropped: {},
      isPageFullyLoaded: false,
      isShow: {
        create: false,
        edit: false,
        destroy: false,
        folder_create: false,
      },
      upload_url: '',
      currentFolder: {},
      pluginSettings: {
        resourceFound: null,
      },
      loading: {
        reloadResource: false,
      },
      apiRoutes: {
        editApiMethod: 'editPluginFolderGroup',
        deleteApiMethod: 'destroyPluginFolderGroup',
        editDocumentApiMethod: 'editGroupDocument',
        permGetApiMethod: 'getGroupFolderPermissions',
        permSetApiMethod: 'setGroupFolderPermission',
        permEditApiMethod: 'updateGroupFolderPermission',
        permDeleteApiMethod: 'deleteGroupFolderPermission',
      },
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'loggedUserGroup', 'groupDocumentsList', 'groupFolders']),
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    calculateBackRoute() {
      if (this.groupFolders) {
        // if currentFolder is defined, check if it has a parent_id and is not 0
        if (this.currentFolder && this.currentFolder.parent_id && this.currentFolder.parent_id !== 0) {
          // find the parent folder
          const parentFolder = this.groupFolders.find(item => item.id === this.currentFolder.parent_id)

          // if it has a parent_id, return the route to the parent folder
          return `/group-folder/${this.loggedUserGroup.id}/folder/${parentFolder.unique_ref}`
        }

        return '/group-documents'
      }

      return '/group-documents'
    },
    currentLevelFolders() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.groupFolders.filter(item => item.parent_id === this.currentFolder.id)
      }

      return []
    },
    currentLevelDocuments() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.groupDocumentsList.filter(item => item.folder_id === this.currentFolder.id)
      }

      return []
    },
    calculateBreadcrumb() {
      // create an array of objects containing the nodes: text, disabled, href
      // the first node has always the name "Spazio condiviso" and the href to the group documents page
      const breadcrumb = [
        {
          text: 'Documenti',
          disabled: false,
          to: '/group-documents',
        },
      ]

      const tempFolders = []
      const findParentFolder = folder => {
        const parentFolder = this.groupFolders.find(item => item.id === folder.parent_id)
        if (parentFolder) {
          // if it has a parent_id, return the route to the parent folder
          tempFolders.push({
            text: parentFolder.name,
            disabled: false,
            to: `/group-folder/${this.loggedUserGroup.id}/folder/${parentFolder.unique_ref}`,
          })
          findParentFolder(parentFolder)
        }
      }

      findParentFolder(this.currentFolder)

      // reverse the array to have the correct order
      tempFolders.reverse()
      breadcrumb.push(...tempFolders)

      // the last node has always the name of the current folder and is disabled because is the current page
      breadcrumb.push({
        text: this.currentFolder.name,
        disabled: true,
      })

      return breadcrumb
    },
  },
  watch: {
    '$route.params.fid': function () {
      this.setCurrentFolder()
    },
  },
  async created() {
    this.getData()
      .then(() => this.setCurrentFolder())
      .then(() => this.setPageIsFullyLoaded(true))
  },
  async mounted() {
    if (this.isPageFullyLoaded) {
      try {
        await this.getData()
        await this.setResourceFound(true)
      } catch (error) {
        if (error.status === 404) {
          await this.setResourceFound(false)
        } else {
          console.log(error)
        }
      }
    }
  },
  methods: {
    async getData() {
      this.loading.reloadResource = true
      await this.$store.dispatch('getGroup', this.loggedUserGroup.id)
      await this.$store.dispatch('getGroupDocuments')
      this.isDropped = {}
      this.loading.reloadResource = false
    },
    async setResourceFound(status) {
      this.pluginSettings.resourceFound = status
    },
    async setPageIsFullyLoaded(status) {
      this.isPageFullyLoaded = status
    },
    async setCurrentFolder() {
      if (this.groupFolders) {
        this.currentFolder = await this.groupFolders.find(item => item.unique_ref === this.$route.params.fid)
      }
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
  },
}
</script>

<style scoped>
  .v-breadcrumbs >>> a {
    color: rgb(143, 137, 152) !important;
  }
</style>
